<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="!readOnly ? rules : {}">
      <a-form-model-item label="提现流水号" prop="withdrawalNo">
        <a-input :disabled="readOnly" v-model="form.withdrawalNo" placeholder="请输入提现流水号" />
      </a-form-model-item>
      <a-form-model-item label="所属用户" prop="createBy">
        <a-input :disabled="readOnly" v-model="form.createBy" placeholder="请输入所属用户" />
      </a-form-model-item>
      <a-form-model-item label="提现姓名" prop="username">
        <a-input :disabled="readOnly" v-model="form.username" placeholder="请输入提现姓名" />
      </a-form-model-item>
      <a-form-model-item label="提现银行卡号" prop="bankNo">
        <a-input :disabled="readOnly" v-model="form.bankNo" placeholder="请输入提现银行卡号" />
      </a-form-model-item>
      <a-form-model-item label="提现开户行" prop="bankName">
        <a-input :disabled="readOnly" v-model="form.bankName" placeholder="请输入提现开户行" />
      </a-form-model-item>
      <a-form-model-item label="提现金额（元）" prop="amount">
        <a-input :disabled="readOnly" v-model="form.amount" placeholder="请输入提现金额" />
      </a-form-model-item>
      <a-form-model-item label="提现状态" prop="withdrawalStatus">
        <a-input
          :disabled="readOnly"
          :value="selectDictLabel(withdrawalStatusOptions, form.withdrawalStatus)"
          placeholder="提现状态"
        />
        <!-- <a-radio-group v-model="form.withdrawalStatus" button-style="solid">
          <a-radio-button v-for="(item, index) in withdrawalStatusOptions" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-radio-button>
        </a-radio-group> -->
      </a-form-model-item>
      <a-form-model-item label="提现操作人员" prop="backendUserName">
        <a-input :disabled="readOnly" v-model="form.backendUserName" placeholder="--" />
      </a-form-model-item>
      <!-- <a-form-model-item label="是否删除" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(item, index) in statusOptions" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <div class="bottom-control" v-if="!readOnly">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWithdrawalRecord, addWithdrawalRecord, updateWithdrawalRecord } from '@/api/recyclable/withdrawalRecord'
import { statusOptions, withdrawalStatusOptions } from './options'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        withdrawalNo: null,
        userId: null,
        username: null,
        bankNo: null,
        bankName: null,
        amount: null,
        withdrawalStatus: 0,
        backendUserId: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      readOnly: true,
      rules: {
        withdrawalNo: [{ required: true, message: '提现流水号不能为空', trigger: 'blur' }],
        userId: [{ required: true, message: '所属用户不能为空', trigger: 'blur' }],
        username: [{ required: true, message: '提现姓名不能为空', trigger: 'blur' }],
        bankNo: [{ required: true, message: '提现银行卡号不能为空', trigger: 'blur' }],
        bankName: [{ required: true, message: '提现开户行不能为空', trigger: 'blur' }],
        amount: [{ required: true, message: '提现金额不能为空', trigger: 'blur' }],
        withdrawalStatus: [{ required: true, message: '提现状态', trigger: 'blur' }],
        backendUserId: [{ required: true, message: '提现操作人员', trigger: 'blur' }]
      },
      statusOptions,
      withdrawalStatusOptions
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        withdrawalNo: null,
        userId: null,
        username: null,
        bankNo: null,
        bankName: null,
        amount: null,
        withdrawalStatus: 0,
        backendUserId: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWithdrawalRecord(id).then(response => {
        this.form = response.data
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key] + ''
          }
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWithdrawalRecord(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addWithdrawalRecord(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
