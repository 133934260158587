export const statusOptions = [
  { dictLabel: '正常', dictValue: '0' },
  { dictLabel: '删除', dictValue: '1' }
]

export const withdrawalStatusOptions = [
  { dictLabel: '全部', dictValue: '' },
  { dictLabel: '在途', dictValue: '1' },
  { dictLabel: '已到账', dictValue: '2' }
]
